// action - state management
import * as actionTypes from './serviceActions';

export const initialState = {
    clientForm: null,
    serviceForm: null,
    contactForm: null,
    billingForm: null,
    submittedForm: false,
    formId: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_CLIENT_FORM:
            return {
                ...state,
                clientForm: action.values,
                submittedForm: false,
                formId: null
            };
        case actionTypes.STORE_SERVICE_FORM:
            return {
                ...state,
                serviceForm: action.values
            };
        case actionTypes.STORE_CONTACT_FORM:
            return {
                ...state,
                contactForm: action.values
            };
        case actionTypes.STORE_BILLING_FORM:
            return {
                ...state,
                billingForm: action.values
            };
        case actionTypes.SUBMIT_FORM:
            return {
                ...state,
                contactForm: null,
                serviceForm: null,
                clientForm: null,
                billingForm: null,
                submittedForm: true,
                formId: action.id
            };
        default:
            return state;
    }
};

export default serviceReducer;
