import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import MinimalLayoutLogo from 'layout/MinimalLayoutLogo';
import Loadable from 'ui-component/Loadable';
import AuthRoute from 'helpers/AuthRoute';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthLogin2 = Loadable(lazy(() => import('views/pages/authentication/authentication2/Login2')));

const Services = Loadable(lazy(() => import('views/services')));

const BankLogin = Loadable(lazy(() => import('views/pages/authentication/bankLogin')));

const ProfilePage = Loadable(lazy(() => import('views/pages/profile/data')));
const PasswordPage = Loadable(lazy(() => import('views/pages/profile/password')));

const BillsPage = Loadable(lazy(() => import('views/pages/bills')));
const AdvancePaymentPage = Loadable(lazy(() => import('views/pages/advance-payment')));
const AgreementsPage = Loadable(lazy(() => import('views/pages/agreements')));
const AddressesPage = Loadable(lazy(() => import('views/pages/addresses')));
const CalendarPage = Loadable(lazy(() => import('views/pages/calendar')));

const UsersPage = Loadable(lazy(() => import('views/pages/users')));

const InvitePage = Loadable(lazy(() => import('views/pages/invite')));
const AppCalendar = Loadable(lazy(() => import('views/application/calendar')));
const ApplyServicePage = Loadable(lazy(() => import('views/pages/apply-service')));
const PaymentsFailurePage = Loadable(lazy(() => import('views/pages/payments/failure')));
const PaymentsSuccessPage = Loadable(lazy(() => import('views/pages/payments/success')));
const RulesPage = Loadable(lazy(() => import('views/pages/rules')));
const PaymentsCancelPage = Loadable(lazy(() => import('views/pages/payments/cancel')));
const OnboardingPage = Loadable(lazy(() => import('views/pages/onboarding')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: (
                    <AuthRoute>
                        <DashboardDefault />
                    </AuthRoute>
                )
            },
            {
                path: '/onboarding',
                element: (
                    <AuthRoute>
                        <OnboardingPage />
                    </AuthRoute>
                )
            },
            {
                path: '/payments/failure',
                element: <PaymentsFailurePage />
            },
            {
                path: '/payments/success',
                element: <PaymentsSuccessPage />
            },
            {
                path: '/services',
                element: (
                    <AuthRoute>
                        <Services />
                    </AuthRoute>
                )
            },
            {
                path: '/pieteikt-pakalpojumu',
                element: (
                    <AuthRoute>
                        <ApplyServicePage formType="registered" />
                    </AuthRoute>
                )
            },
            {
                path: '/profile',
                element: (
                    <AuthRoute>
                        <ProfilePage />
                    </AuthRoute>
                )
            },
            {
                path: '/profile/password',
                element: (
                    <AuthRoute>
                        <PasswordPage />
                    </AuthRoute>
                )
            },
            {
                path: '/bills',
                element: (
                    <AuthRoute>
                        <BillsPage />
                    </AuthRoute>
                )
            },
            {
                path: '/advance-payment',
                element: (
                    <AuthRoute>
                        <AdvancePaymentPage />
                    </AuthRoute>
                )
            },
            {
                path: '/agreements',
                element: (
                    <AuthRoute>
                        <AgreementsPage />
                    </AuthRoute>
                )
            },
            {
                path: '/addresses',
                element: (
                    <AuthRoute>
                        <AddressesPage />
                    </AuthRoute>
                )
            },
            {
                path: '/calendar/:id',
                element: (
                    <AuthRoute>
                        <CalendarPage />
                    </AuthRoute>
                )
            },
            {
                path: '/calendar',
                element: (
                    <AuthRoute>
                        <CalendarPage />
                    </AuthRoute>
                )
            },
            {
                path: 'dashboard',
                children: [
                    {
                        path: 'default',
                        element: <DashboardDefault />
                    }
                ]
            },
            {
                path: '/users',
                element: (
                    <AuthRoute>
                        <UsersPage />
                    </AuthRoute>
                )
            },
            {
                path: '/rules',
                element: <RulesPage />
            }
        ]
    },
    {
        path: '/pieteikt-pakalpojumu-neregistrets',
        element: <MinimalLayoutLogo />,
        children: [
            {
                path: '/pieteikt-pakalpojumu-neregistrets',
                element: <ApplyServicePage formType="unregistered" />
            }
        ]
    },
    {
        path: '/bank_auth',
        element: <MinimalLayout />,
        children: [
            {
                path: '/bank_auth',
                element: <BankLogin />
            }
        ]
    },
    {
        path: '/login',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <AuthLogin2 />
            }
        ]
    },
    {
        path: '/invite/:id',
        element: <MinimalLayout />,
        children: [
            {
                path: '/invite/:id',
                element: <InvitePage />
            }
        ]
    },
    {
        path: '/payments/cancel',
        element: <MinimalLayoutLogo />,
        children: [
            {
                path: '/payments/cancel',
                element: <PaymentsCancelPage />
            }
        ]
    }
];

export default MainRoutes;
