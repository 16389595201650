// action - state management
import * as actionTypes from './authActions';

export const initialState = {
    user: localStorage.getItem('user') ?? null,
    userdata: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                user: { access_token: action.access_token, refresh_token: action.refresh_token }
            };
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                user: null,
                userdata: null
            };
        case actionTypes.USER_LOADED:
            return {
                ...state,
                user: { access_token: action.access_token, refresh_token: action.refresh_token }
            };
        case actionTypes.AUTH_LOGIN_STARTED:
            return {
                ...state
            };
        case actionTypes.USER_DATA_LOADED:
            return {
                ...state,
                userdata: action.userdata.data
            };
        default:
            return state;
    }
};

export default authReducer;
