import axios from 'axios';
import { axiosApiInstance, setAuthTokens } from 'helpers/api';

export const AUTH_LOGIN_STARTED = '@auth/AUTH_LOGIN_STARTED';
export const AUTH_LOGIN_SUCCESS = '@auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = '@auth/AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT = '@auth/AUTH_LOGOUT';
export const USER_LOADED = '@auth/USER_LOADED';

export const USER_DATA_LOADED = '@auth/USER_DATA_LOADED';

export const login = (user, password) => {
    return (dispatch) => {
        return axios
            .post(process.env.REACT_APP_API_URL + 'login', { user, password })
            .then((token) => {
                const userObject = { access_token: token.access_token, refresh_token: token.refresh_token };
                //localStorage.setItem('user', JSON.stringify(userObject));
                setAuthTokens(token.access_token, token.refresh_token);
                //localStorage.setItem('user', userObject);
                dispatch({
                    type: AUTH_LOGIN_SUCCESS,
                    token: token.data
                });
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: AUTH_LOGIN_FAIL
                });
                throw error;
            });
    };
};

export const loginByToken = (access_token, refresh_token) => {
    return (dispatch) => {
        const userObject = { access_token: access_token, refresh_token: refresh_token };
        localStorage.setItem('user', JSON.stringify(userObject));
        setAuthTokens(access_token, refresh_token);
        dispatch({
            type: AUTH_LOGIN_SUCCESS,
            access_token: access_token,
            refresh_token: refresh_token
        });
    };
};

export const loadUser = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        if (user) {
            dispatch({
                type: USER_LOADED,
                access_token: user.access_token,
                refresh_token: user.refresh_token
            });
        } else return null;
    };
};

export const loadUserData = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        if (user) {
            return axiosApiInstance.get('/api/selfservice/user/').then((data) => {
                dispatch({
                    type: USER_DATA_LOADED,
                    userdata: data
                });
            });
        } else return null;
    };
};

export const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return (dispatch) => {
        dispatch({
            type: AUTH_LOGOUT
        });
    };
};

/*
export const login = (username, password) => (dispatch) => {
    dispatch({
        type: AUTH_LOGIN_STARTED
    });
    console.log('aaa');
    const auth = new AuthService();
    console.log('xxx');
    return auth.login(username, password).then(
        (data) => {
            dispatch({
                type: AUTH_LOGIN_SUCCESS,
                payload: { user: data }
            });
        },
        (error) => {
            console.log('yy');
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            dispatch({
                type: AUTH_LOGIN_FAIL,
                payload: message
            });
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT
    });
};

*/
