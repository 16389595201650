import axios from 'axios';

export const redirectToLogin = () => {
    window.location = '/login';
};

const axiosApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const axiosPlainApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const setAuthTokens = (access, refresh) => {
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
};

export const clearAuthTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
};

axiosApiInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosApiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Refresh the token
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(process.env.REACT_APP_API_URL + '/api/token/refresh/', {
                    // Include any necessary data for token refreshing
                    refresh: refreshToken
                });
                const { access } = response.data;
                localStorage.setItem('accessToken', access);
                // Update the access token
                originalRequest.headers = { ...originalRequest.headers };
                originalRequest.headers['Authorization'] = `Bearer ${access}`;
                return axiosApiInstance(originalRequest);
            } catch (error) {
                // Handle token refresh failure or other errors
                console.error('Error refreshing token:', error);
                clearAuthTokens();
                redirectToLogin();
                return Promise.reject(error);

                // Redirect to the login page or perform any necessary actions
            }
        }

        return Promise.reject(error);
    }
);
/*
IT WORKED
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config) => {
        const user = localStorage.getItem('user');
        const keys = JSON.parse(user);
        config.headers = {
            Authorization: `Bearer ${keys.access_token}`,
            Accept: 'application/json'
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);


async function refreshToken() {
    const user = localStorage.getItem('user');
    const keys = JSON.parse(user);
    const data = await axiosApiInstance.post('/api/token/refresh/', {
        refresh: keys.refresh_token
    });
    return await data.data.access;
}



// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error && error.response && error.response.status === 401 && !originalRequest._retry) {
            //401?
            originalRequest._retry = true;
            
            const access_token = await refreshToken();
            console.log('a', access_token);
            const user = localStorage.getItem('user');
            const keys = JSON.parse(user);
            keys.access_token = access_token;
            localStorage.setItem('user', JSON.stringify(keys));
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            return axiosApiInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);
*/
export { axiosApiInstance, axiosPlainApi };
