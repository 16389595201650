import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import { loadUser, loadUserData } from 'store/authActions';
import { axiosPlainApi } from 'helpers/api';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import NotAvailable from 'views/pages/notavailable';
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const { user: authUser } = useSelector((x) => x.auth);
    const dispatch = useDispatch();
    const [available, setAvailable] = useState(true);

    const getSiteStatus = async () => {
        const systemStatus = await axiosPlainApi.get('/api/config');
        let available = true;
        if (systemStatus?.data?.SITE_ENABLED == false) {
            available = false;
        }
        setAvailable(available);
    };

    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);

    useEffect(() => {
        if (authUser) {
            dispatch(loadUserData());
        }
    }, [authUser]);

    useEffect(() => {
        getSiteStatus();
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>{available ? <Routes /> : <NotAvailable />}</NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
