import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from 'helpers/history';

function AuthRoute({ children }) {
    const { user: authUser } = useSelector((x) => x.auth);
    if (!authUser && !localStorage.getItem('accessToken')) {
        return <Navigate to="/login" state={{ from: history.location }} />;
    }

    return children;
}

export default AuthRoute;
