// assets
import { IconKey, Icon3dCubeSphere, IconClipboardList, IconBuildingWarehouse, IconClipboardPlus } from '@tabler/icons';

// constant
const icons = { IconKey, Icon3dCubeSphere, IconClipboardList, IconBuildingWarehouse, IconClipboardPlus };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const services = {
    id: 'services',
    title: 'Pakalpojumi',
    type: 'group',
    children: [
        {
            id: 'agreements',
            title: 'Līgumi',
            type: 'item',
            url: '/agreements',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        },
        {
            id: 'addresses',
            title: 'Adreses',
            type: 'item',
            url: '/addresses',
            icon: icons.IconBuildingWarehouse,
            breadcrumbs: false
        },
        {
            id: 'calendar',
            title: 'Kalendārs',
            type: 'item',
            url: '/calendar',
            icon: icons.Icon3dCubeSphere,
            breadcrumbs: false
        }
    ]
};

export default services;
