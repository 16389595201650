export const STORE_CLIENT_FORM = '@services/STORE_CLIENT_FORM';
export const STORE_SERVICE_FORM = '@services/STORE_SERVICE_FORM';
export const STORE_CONTACT_FORM = '@services/STORE_CONTACT_FORM';
export const STORE_BILLING_FORM = '@services/STORE_BILLING_FORM';
export const SUBMIT_FORM = '@services/SUBMIT_FORM';
import { axiosPlainApi } from 'helpers/api';

export const storeClientForm = (values) => {
    return (dispatch, getState) => {
        console.log('xxx', values);
        dispatch({
            type: STORE_CLIENT_FORM,
            values: values
        });
    };
};

export const storeServiceForm = (values) => {
    return (dispatch, getState) => {
        console.log('yyy', values);
        dispatch({
            type: STORE_SERVICE_FORM,
            values: values
        });
    };
};

export const storeContactForm = (values) => {
    return (dispatch, getState) => {
        dispatch({
            type: STORE_CONTACT_FORM,
            values: values
        });
    };
};

export const storeBillingForm = (values) => {
    return (dispatch, getState) => {
        dispatch({
            type: STORE_BILLING_FORM,
            values: values
        });
    };
};

export const submitForm = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (state) {
            return axiosPlainApi.post('/api/selfservice/post_notification/', state).then((response) => {
                dispatch({
                    type: SUBMIT_FORM,
                    id: response.data.id
                });
            });
        } else return null;
    };
};
