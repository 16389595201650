import dashboard from './dashboard';
import services from './services';
import payments from './payments';
import messages from './messages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, services, payments, messages]
};

export default menuItems;
