import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import Logo from 'assets/images/zaao-logo2.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <>
        <ButtonBase disableRipple>
            <a href="https://zaao.lv">
                <img src={Logo} alt="ZAAO" width={50} />
            </a>
        </ButtonBase>
    </>
);

export default LogoSection;
