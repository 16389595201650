// assets
import { IconDashboard, IconDeviceDesktop, IconUser } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDeviceDesktop, IconUser };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Klients',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Sākums',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'profile',
            title: 'Mani dati',
            type: 'item',
            url: '/profile',
            icon: icons.IconDeviceDesktop,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Lietotāji',
            type: 'item',
            url: '/users',
            icon: icons.IconUser,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
