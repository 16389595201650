export const canUserApplyService = (userData) => {
    if (process.env.REACT_APP_WEB_ENV == 'production') {
        //return false;
    }
    for (const i in userData) {
        if (userData[i].actual == true && [1, 4].includes(userData[i].role)) {
            return true;
        }
    }
    return false;
};

export const getActualUser = (userData) => {
    if (userData && userData.length == 1) {
        return userData[0];
    }
    for (const i in userData) {
        if (userData[i].actual == true) {
            return userData[i];
        }
    }
    return null;
};
