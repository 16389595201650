import { Divider, Grid, Stack, Typography, useMediaQuery, Box, Button } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import MinimalLayout from 'layout/MinimalLayout';
import BankList from 'views/pages/authentication/auth-forms/BankList';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import MinimalLayoutLogo from 'layout/MinimalLayoutLogo';
import AuthWrapper2 from '../authentication/AuthWrapper2';
import Logo from 'assets/images/zaao-logo2.png';
import AuthCardWrapper from '../authentication/AuthCardWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthSlider from 'ui-component/cards/AuthSlider';
import BackgroundPattern2 from 'ui-component/cards/BackgroundPattern2';
import imgMain from 'assets/images/zaao_portals.jpg';
import { Link as MLink } from '@mui/material';

const NotAvailablePage = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const noCustomer = true;
    /*
    return (
        <Grid container direction="column" justifyContent="flex-end">
            <Grid item xs={12}>
                <MainCard sx={{ m: 3 }}>
                    <Grid container spacing={3} sx={{ p: 5 }}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '1.5rem' }}>
                                Šobrīd veicam uzlabojumus, sistēma uz brīdi nav pieejama!
                            </Typography>
                            <Typography variant="subtitle1" sx={{ fontSize: '1.5rem' }}>
                                Lūdzam izmantot portāla <a href="https://klientiem.zaao.lv">veco versiju</a>.
                            </Typography>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
        </Grid>
    );
    */
    return (
        <>
            <AuthWrapper2>
                <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center">
                    <Grid item md={6} lg={7} xs={12} sx={{ minHeight: '100vh' }}>
                        <Grid
                            sx={{ minHeight: '100vh' }}
                            container
                            alignItems={matchDownSM ? 'center' : 'flex-start'}
                            justifyContent={matchDownSM ? 'center' : 'space-between'}
                        >
                            <Grid item sx={{ display: { xs: 'none', md: 'block' }, m: 0 }}>
                                <Box pl={2}>
                                    <Link to="#">
                                        <img src={Logo} alt="ZAAO" width={100} />
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{ minHeight: { xs: 'calc(100vh - 68px)', md: 'calc(100vh - 152px)' } }}
                            >
                                <Stack justifyContent="center" alignItems="center" spacing={2} m={2}>
                                    <Box component={Link} to="#" sx={{ display: { xs: 'block', md: 'none' } }}>
                                        <img src={Logo} alt="ZAAO" width={100} />
                                    </Box>
                                    <MainCard
                                        sx={{
                                            maxWidth: { xs: 450, lg: 605 },
                                            margin: { xs: 2.5, md: 3 },
                                            '& > *': {
                                                flexGrow: 1,
                                                flexBasis: '50%'
                                            }
                                        }}
                                        content={false}
                                        border={matchDownMD}
                                    >
                                        <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                                            <Grid container spacing={0} justifyContent="center">
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography
                                                            color={theme.palette.secondary.main}
                                                            gutterBottom
                                                            variant={matchDownSM ? 'h3' : 'h3'}
                                                        >
                                                            Atvainojiet, klientu portāls šobrīd nav pieejams!
                                                        </Typography>
                                                        <Box>
                                                            <Typography
                                                                color={'red'}
                                                                gutterBottom
                                                                variant={matchDownSM ? 'h4' : 'h3'}
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    color: '#000',
                                                                    textAlign: 'center',
                                                                    fontSize: '14px'
                                                                }}
                                                            >
                                                                Saziņai ar SIA "ZAAO" lūdzam izmantot
                                                                <Typography sx={{ mt: 1.2, mb: 1.2, fontSize: '14px', fontWeight: 500 }}>
                                                                    e-pastu <MLink href="mailto:zaao@zaao.lv">zaao@zaao.lv</MLink>
                                                                    vai tālruni <MLink href="tel:64281250">64281250</MLink>
                                                                </Typography>
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                {!noCustomer && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <AuthLogin loginProp={2} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider />
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Box>
                                    </MainCard>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{ m: 3 }}>
                                <AuthFooter />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} lg={5} sx={{ position: 'relative', alignSelf: 'stretch', display: { xs: 'none', md: 'block' } }}>
                        <BackgroundPattern2>
                            <Grid item container justifyContent="end">
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ position: 'relative', backgroundImage: `url(${imgMain})`, backgroundSize: 'cover' }}
                                />
                            </Grid>
                        </BackgroundPattern2>
                    </Grid>
                </Grid>
            </AuthWrapper2>
        </>
    );
};
export default NotAvailablePage;
