import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery,
    TextField
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
// assets
import { Link } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import SebLogo from 'assets/images/SEB_logo.png';
import EparakstsMobile from 'assets/images/eparaksts-mobile.png';
import EparakstsPlugin from 'assets/images/eparaksts-logo.png';
import SwedbankLogo from 'assets/images/swedbank-logo.png';
import HSBCLogo from 'assets/images/hsbc-logo.png';
import LuminorLogo from 'assets/images/luminor-logo.png';
import SmartIdLogo from 'assets/images/smartid-logo.png';
import CitadeleLogo from 'assets/images/Citadele_logo_RGB.png';
import { DownloadButton } from 'ui-component/CustomButton';

import { login } from 'store/authActions';
import { axiosPlainApi } from 'helpers/api';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));

const BankList = ({ ...others }) => {
    const invite_id = others?.invite || null;
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [smartIdCode, setSmartIdCode] = useState(null);
    const [smartIdSession, setSmartIdSession] = useState(null);
    const classes = useStyles();
    const handleClickBank = async () => {
        const linkdata = await axiosPlainApi.post('/api/bank/initialize/100', {
            invite: invite_id
        });
        window.location = linkdata.data.bank_url;
    };

    const handleClickEparaksts = async () => {
        const linkdata = await axiosPlainApi.post('/api/eparaksts/initialize/auth/mobile/', {
            invite: invite_id
        });
        window.location = linkdata.data.redirect_uri;
    };

    const handleClickSmartID = async () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleKeyPress = (event) => {
        // Allow only digits, backspace, and arrow keys
        if (!/^\d$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleClickEparakstsPlugin = async () => {
        const linkdata = await axiosPlainApi.post('/api/eparaksts/initialize/auth/eid/', {
            invite: invite_id
        });
        window.location = linkdata.data.redirect_uri;
    };

    const handleClickSwedbank = async () => {
        const linkdata = await axiosPlainApi.post('/api/bank/initialize/1', {
            invite: invite_id
        });
        window.location = linkdata.data.bank_url;
    };

    const handleClickSEB = async () => {
        const linkdata = await axiosPlainApi.post('/api/bank/initialize/2', {
            invite: invite_id
        });
        window.location = linkdata.data.bank_url;
    };

    const handleClickLuminor = async () => {
        const linkdata = await axiosPlainApi.post('/api/bank/initialize/3', {
            invite: invite_id
        });
        window.location = linkdata.data.bank_url;
    };

    const handleClickCitadele = async () => {
        const linkdata = await axiosPlainApi.post('/api/bank/initialize/4', {
            invite: invite_id
        });
        window.location = linkdata.data.bank_url;
    };

    const validateSchema = Yup.object().shape({
        personal_id: Yup.mixed().test('is-amount-set', 'Šis lauks ir obligāts', function (value) {
            if (value.length < 11) {
                return false;
            }
            return true;
        })
    });

    const submitFormData = async (params) => {
        const formDataUpdate = await axiosPlainApi.get('/api/selfservice/init_smartid?personal_id=' + params.personal_id);
        setSmartIdCode(formDataUpdate.data.verification_code);
        setSmartIdSession(formDataUpdate.data.session_id);
        if (formDataUpdate.data.session_id) {
            const smartIdSessionData = await axiosPlainApi.get(
                '/api/selfservice/check_smartid_session?session_id=' + formDataUpdate.data.session_id + '&invite=' + invite_id
            );
            if (smartIdSessionData.data.session_id && smartIdSessionData.data.return_url) {
                window.location = smartIdSessionData.data.return_url;
            }
        }
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                onClose={handleClose}
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'left'
                    }
                }}
            >
                <DialogTitle>
                    <Typography variant="h3">Autorizācija ar Smart ID</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Formik
                        validationSchema={validateSchema}
                        initialValues={{
                            personal_id: ''
                        }}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting, setFieldValue }) => {
                            try {
                                submitFormData(values);
                            } catch (err) {
                                console.error(err);
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" gutterBottom>
                                            Ievadiet personas kodu lai turpinātu
                                        </Typography>
                                    </Grid>
                                    {smartIdCode && (
                                        <Grid item xs={12}>
                                            Drošibas kods: {smartIdCode}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            fullWidth
                                            id="personal_id"
                                            name="personal_id"
                                            onKeyPress={handleKeyPress}
                                            label="Personas kods"
                                            value={values.personal_id}
                                            onChange={handleChange}
                                            error={touched.personal_id && Boolean(errors.personal_id)}
                                            helperText={touched.personal_id && errors.personal_id}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} sx={{ mt: 0.5 }}>
                                        <DownloadButton variant="contained" fullWidth type="submit">
                                            Autorizēties
                                        </DownloadButton>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            {process.env.REACT_APP_WEB_ENV != 'production' && (
                <Grid item xs={12}>
                    <AnimateButton>
                        <Button
                            onClick={handleClickBank}
                            disableElevation
                            fullWidth
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100]
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 30 } }}>
                                <img src={HSBCLogo} alt="google" width={40} height={40} />
                            </Box>
                            Sign in with test login
                        </Button>
                    </AnimateButton>
                </Grid>
            )}
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickSmartID}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box>
                            <img src={SmartIdLogo} height={40} alt="smart id" />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickEparaksts}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box>
                            <img src={EparakstsMobile} height={40} alt="google" />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickEparakstsPlugin}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box>
                            <img src={EparakstsPlugin} alt="google" height={40} />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickSwedbank}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box>
                            <img src={SwedbankLogo} alt="google" height={40} />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickSEB}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box sx={{ mr: { xs: 0, sm: 0, width: 100 } }}>
                            <img src={SebLogo} alt="google" height={40} />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickCitadele}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box sx={{ mr: { xs: 0, sm: 0, width: 100 } }}>
                            <img src={CitadeleLogo} alt="Citadele" height={40} />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item xs={12}>
                <AnimateButton>
                    <Button
                        onClick={handleClickLuminor}
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box sx={{ mr: { xs: 0, sm: 0, width: 100 } }}>
                            <img src={LuminorLogo} alt="Luminor" height={40} />
                        </Box>
                    </Button>
                </AnimateButton>
            </Grid>
        </>
    );
};

export default BankList;
