import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const DownloadButton = styled(Button)(() => ({
    backgroundColor: 'rgb(166, 207, 163)',
    color: '#000',
    '&:hover': {
        color: '#fff'
    }
}));
