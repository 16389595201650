// assets
import { IconBrandPagekit, IconHistory } from '@tabler/icons';

// constant
const icons = { IconBrandPagekit, IconHistory };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
let payments = {};

if (process.env.REACT_APP_WEB_ENV != 'production') {
    payments = {
        id: 'payments',
        title: 'Maksājumi',
        type: 'group',
        children: [
            {
                id: 'bills',
                title: 'Rēķini',
                type: 'item',
                url: '/bills',
                icon: icons.IconBrandPagekit,
                breadcrumbs: false
            },
            {
                id: 'advance-payment',
                title: 'Maksāt avansā',
                type: 'item',
                allow: ['private'],
                url: '/advance-payment',
                icon: icons.IconHistory,
                breadcrumbs: false
            }
        ]
    };
} else {
    payments = {
        id: 'payments',
        title: 'Maksājumi',
        type: 'group',
        children: [
            {
                id: 'bills',
                title: 'Rēķini',
                type: 'item',
                url: '/bills',
                icon: icons.IconBrandPagekit,
                breadcrumbs: false
            },
            {
                id: 'advance-payment',
                title: 'Maksāt avansā',
                type: 'item',
                allow: ['private'],
                url: '/advance-payment',
                icon: icons.IconHistory,
                breadcrumbs: false
            }
        ]
    };
}

export default payments;
